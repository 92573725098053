.dashboardContainer {
    display: grid;
    height: 100vh;
    grid-template-areas: 
      'head head head' 
      'sideBar users users'
      'sideBar users users'
    ;
    grid-template-columns: 1fr 2fr 2fr;
}

.header {
    grid-area: head;
}

.sidebar {
    grid-area: sideBar;
    overflow: auto;
}

.patient-detail {
    grid-area: users;
    // padding-top: 120px;
    // padding-left: 60px;
    background-color: rgb(248, 247, 247);
    @media screen and (max-width: 425px) {
        padding-left: 20px;
    }
    @media screen and (max-width: 768px) and (min-width: 426px) {
        padding-left: 20px;
    }
    @media screen and (max-width: 1023px) and (min-width: 769px) {
        padding-left: 20px;
    }
}