.overview-container {
    margin-left: 3rem;
    margin-top: 5rem;
    @media screen and (max-width: 800px) {
        margin-left: 0;
    }
}

.main {
    display: grid;
    max-width: 90%;
    border-radius: 2rem;
    justify-self: center;
    background-color: rgb(84, 84, 214);
    color: white;
    grid-template-areas: 
      'left right' 
    ;
    grid-template-columns: 2fr 2fr;
    @media screen and (max-width: 800px) {
        grid-template-areas: 
        'left'
        'right' 
        ;
        grid-template-columns: 2fr;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.left {
    grid-area: left;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 3rem;
}

.right {
    grid-area: right;
    @media screen and (max-width: 800px) {
        display: none;
    }
}

.right img {
    width: 21vw;
    background-color: transparent;
}

.doc {
    font-size: 2rem;
    font-weight: 600;
}

.book {
    background-color: white;
    min-width: 5vw;
    padding: 0.8rem;
    margin-bottom: 2.8rem;
    color: rgb(67, 67, 240);
    border-radius: 12px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
}

.book:hover {
    cursor: pointer;
    background-color: rgb(214, 213, 213);
}

