.signin-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
}

.signup-line {
    border-bottom: 1px solid rgb(189, 188, 188);
    margin-top: 0.5rem;
}

.top-signin {
    margin: 4rem;
}

.signin-error {
    @media screen and (max-width: 425px) {
        max-width: 70%;
        position: absolute;
        margin-top: 0.3rem;
    }
}

.logBtn {
    @media screen and (max-width: 425px) {
        margin-top: 3.2rem;
    }
}