.appointmentContainer {
    display: grid;
    height: 100vh;
    grid-template-areas: 
      'head head head' 
      'sideBar users users'
      'sideBar users users'
    ;
    grid-template-columns: 1fr 2fr 2fr;
}

.appointment-header {
    grid-area: head;
}

.appointment-sidebar {
    grid-area: sideBar;
    overflow: auto;
}

.appointment-detail {
    grid-area: users;
    // margin-bottom: 10rem;
    // padding-top: 120px;
    // padding-left: 60px;
    background-color: rgb(248, 247, 247);
    @media screen and (max-width: 425px) {
        padding-left: 20px;
    }
    @media screen and (max-width: 768px) and (min-width: 426px) {
        padding-left: 20px;
    }
    @media screen and (max-width: 1023px) and (min-width: 769px) {
        padding-left: 20px;
    }
}

.choose {
    border: 1px solid black;
    text-decoration: none;
    color: black;
    font-weight: 700;
    width: 30vw;
    padding: 2rem;
    margin: 3rem;
    cursor: pointer;
    border-radius: 10px;
    align-items: center;
    display: grid;
    grid-template-areas: 
      'img text next' 
    ;
    grid-template-columns: 1fr 2fr 1fr;
    @media screen and (max-width: 500px) {
        gap: 15px;
    }
}

.choose img {
   grid-area: img;
   border-radius: 50%; 
   width: 5vw;
}

.doc-text {
   grid-area: text;
   display: flex;
   flex-direction: column;
} 

.next {
    grid-area: next;
}