.headerContainer {
    display: grid;
    position: fixed;
    width: 100%;
    height: 10vh;
    z-index: 3;
    background-color: white;
    padding: 8px;
    grid-template-areas: 
       'logo search details'
    ;
    grid-template-rows: 1fr 2fr 1fr;
    box-shadow: 3px 0px 20px rgba(0, 0, 0, 0.04);
    @media screen and (max-width: 425px) {
        padding-top: 25px;
        height: 8vh;
    }
    @media screen and (max-width: 768px) and (min-width: 426px) {
        padding-top: 22px;
        height: 8vh;
    }
}

.logo {
    grid-area: logo;
    display: flex;
    padding-left: 10px;
    align-items: center;
}

.search {
    display: flex;
    grid-area: search;
}

.details {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    grid-area: details;
    color: #213F7D;
    @media screen and (max-width: 425px) {
        display: none;
    }
    @media screen and (max-width: 768px) and (min-width: 426px) {
        display: none;
    }
}

.details h4 {
    text-decoration: underline;
    cursor: pointer;
}

.search {
    display: flex;
    align-items: center;
}

.search input {
    border-radius: 10px 0px 0px 10px;
    border: 1px solid #213F7D;
    background: transparent;
    padding: 0px 0px 0px 10px;
    height: 6vh;
    width: 50%;
    color: #545F7D;
    outline: none;
    @media screen and (max-width: 425px) {
        padding: 0px 0px 0px 15px;
        width: 65%;
        margin-left: 20px;
    }
}

.search button {
    border-radius: 0px 10px 10px 0px;
    background-color: rgb(84, 84, 214);
    height: 6vh;
    color: white;
    border: none;
    padding: 10px 14px 10px 5px;
    cursor: pointer;
}

.search button:hover {
    background-color: rgb(99, 99, 214);
}

.drop-down {
    cursor: pointer;
}

.hamburger {
    display: none;
    @media screen and (max-width: 425px) {
        display: block;
        margin-right: 15px;
    }
    @media screen and (max-width: 768px) and (min-width: 426px) {
        display: block;
    }
}