.docprofile-container {
    display: grid;
    height: 100vh;
    grid-template-areas: 
      'head head head' 
      'sideBar users users'
      'sideBar users users'
    ;
    grid-template-columns: 1fr 2fr 2fr;
}

.docprofile-header {
    grid-area: head;
}

.docprofile-sidebar {
    grid-area: sideBar;
    overflow: auto;
}

.docprofile-detail {
    grid-area: users;
    overflow: auto;
    // margin-bottom: 10rem;
    // padding-top: 120px;
    // padding-left: 60px;
    background-color: rgb(248, 247, 247);
    @media screen and (max-width: 425px) {
        padding-left: 20px;
    }
    @media screen and (max-width: 768px) and (min-width: 426px) {
        padding-left: 20px;
    }
    @media screen and (max-width: 1023px) and (min-width: 769px) {
        padding-left: 20px;
    }
}

.doctor-profile {
    background-color: white;
    border-radius: 20px;
    max-width: 30vw;
    padding: 2rem;
    margin-left: 10rem;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    @media screen and (max-width: 425px) {
        max-width: 50vw;
        margin-left: 0rem;
    }
    @media screen and (max-width: 800px) and (min-width: 426px) {
        max-width: 42vw;
        margin-left: 4rem;
    }
}

.upper img {
    border-radius: 50%; 
    width: 5vw;
}

.video-call {
    cursor: pointer;
}

.video-call img {
    border-radius: 50%; 
    width: 5vw;
}

.major {
    font-weight: 600;
    font-size: 1.3rem;
}

.upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.doc-name {
    font-weight: 600;
}

.dateAppoint {
    @media screen and (max-width: 425px) {
      max-width: 50vw;
    }
    @media screen and (max-width: 800px) and (min-width: 426px) {
        max-width: 20vw;
    }
}

.dateAppoint input {
    @media screen and (max-width: 800px) {
        min-width: 24vw;
    }
}

.timeAppoint {
    @media screen and (max-width: 425px) {
        max-width: 50vw;
    }
    @media screen and (max-width: 800px) and (min-width: 426px) {
        max-width: 20vw;
    }
}

.timeAppoint input {
    @media screen and (max-width: 425px) {
        min-width: 31vw;
    }
    @media screen and (max-width: 800px) and (min-width: 426px) {
        min-width: 24vw;
    }
}

.appointBtn {
    @media screen and (max-width: 425px) {
        min-width: 38vw;
    }
    @media screen and (max-width: 800px) and (min-width: 426px) {
        min-width: 30vw;
    }
}


